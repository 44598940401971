export const getCabinKindFromUrl = () => {
    if (typeof window === 'undefined') return null;
    const searchParams = new URLSearchParams(window.location.search);
    const cabinKindValues = [];

    for (const [key, value] of searchParams.entries()) {
        if (key.startsWith('filterParams[cabinKind]')) {
            cabinKindValues.push(parseInt(value, 10));
        }
    }

    return cabinKindValues;
};

export const setSearchResultQueryParamsToUrl = (baseUrl, selectedSail, cabinKind) => {
    if (typeof window === 'undefined') return null;
    const fullUrl = baseUrl.startsWith('http') ? baseUrl : `${window.location.origin}${baseUrl}`;
    const url = new URL(fullUrl);

    url.searchParams.set('selectedSail', selectedSail);

    cabinKind.forEach((id, index) => {
        url.searchParams.append(`filterParams[cabinKind][${index}]`, id.toString());
    });

    return url.toString();
};
