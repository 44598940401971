import * as React from 'react';
import TrackVisibility from 'react-on-screen';
import { connect } from 'react-redux';
import { addToFavorites, removeFromFavorites } from '../../../actions/favoritesPageActions';
import { loadMore, loadTopSearchResults } from '../../../actions/searchActions';
import { ReviewsGoogleScript } from '../../../components/DesktopReviews/ReviewsGoogleScript';
import { Newsletter } from '../../../components/Newsletter/Newsletter';
import { Pagination } from '../../../components/Pagination/Pagination';
import * as Colors from '../../../consts/variables';
import { __ } from '../../../helpers/TranslationService';
import { getRelevanceSorting } from '../../../helpers/getSortingBasedOnMarket';
import { currencyMap } from '../../../helpers/priceHelpers';
import { getLowestPrice } from '../../../helpers/searchHelpers';
import { ICountryCode } from '../../../interfaces';
import { ICruiseSearchApiObject, IMobileSearchResultCruise } from '../../../interfaces/ISearchApi';
import { IPageDataReducer } from '../../../reducers/pageData';
import { IState } from '../../../reducers';
import { PaginationLoader } from '../../../components/LoadingIndicator/PaginationLoader';
import { SearchResultItem } from './SearchResultItem/SearchResultItem';

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        padding: 15,
        background: Colors.lightGray,
    },
    itemCount: {
        fontWeight: 'bold',
        margin: '0 0 10px',
    },
};

interface StateProps {
    searchResults: {
        cruises: IMobileSearchResultCruise[];
        numResults: number;
        priorityCruise: IMobileSearchResultCruise;
    };
    topSearchResults: {
        cruises: IMobileSearchResultCruise[];
    };
    searchParams: ICruiseSearchApiObject;
    displayTopResults?: boolean;
    favorites: number[];
    portalId: string;
    deviceType: string;
    searchPageType?: 'map' | 'destination' | 'ship' | 'mapWithNoUGC';
    pageData: IPageDataReducer;
    isLoading: boolean;
}

interface DistpachProps {
    loadTopSearchResults: () => void;
    loadMore: (page?: number) => Promise<boolean>;
    addToFavorites: typeof addToFavorites;
    removeFromFavorites: typeof removeFromFavorites;
    discountThreshold: number;
}

type Props = StateProps & DistpachProps & { setSortOrder: (string) => any };

const getSortedCabinFilters = (cabinFilters: string[]) => {
    // DREAM-17897 show cabin filters in the next order
    // ['inside', 'outside', 'balcony', 'suite', 'shipinship']
    // by sorting cabin types IDs from 'CabinIdsMap' ordering
    return cabinFilters?.sort((a, b) => Number(a) - Number(b)) || [];
};

class SearchResultListClass extends React.Component<Props, any> {
    componentDidMount() {
        if (this.props.displayTopResults) {
            this.props.loadTopSearchResults();
        }
    }

    replaceRelevanceSortString(): string {
        return getRelevanceSorting({ market: this.props.portalId as ICountryCode });
    }

    handleSortOrderChange(event) {
        const target = event.target;
        this.props.setSortOrder(target.value);
    }

    renderResults = (cruises) => {
        const t = cruises.map((cruise, index) => {
            const isFavorite = this.props.favorites.indexOf(cruise.cruiseNid) > -1;

            const {
                portalId,
                addToFavorites,
                removeFromFavorites,
                discountThreshold,
                searchPageType,
            } = this.props;
            const searchResultProps = {
                index,
                cruise,
                isFavorite,
                portalId,
                addToFavorites,
                removeFromFavorites,
                discountThreshold,
                searchPageType,
                deviceType: cruise.deviceType,
                sortString: cruise.sortString,
            };

            return (
                <TrackVisibility
                    key={index}
                    partialVisibility={false}
                    throttleInterval={2000}
                    // data-test-id is not accepted by library
                    className="search-result-item"
                >
                    <SearchResultItem
                        {...searchResultProps}
                        cabinKindFilterParams={getSortedCabinFilters(
                            this.props.searchParams.filterParams.cabinKind,
                        )}
                    />
                </TrackVisibility>
            );
        });
        if (!!cruises.length) {
            t.splice(1, 0, <Newsletter key="-1" isCollapsed={true} />);
        }
        return t;
    };

    render() {
        const results = this.props.displayTopResults
            ? this.props.topSearchResults.cruises
            : this.props.searchResults.cruises;
        const { priorityCruise, numResults } = this.props.searchResults;
        const { deviceType } = this.props;
        const pages = Math.ceil(numResults / 10);
        const currentPage = (this.props?.searchParams?.offset + 10) / 10 || 0;
        const {
            portalId,
            addToFavorites,
            removeFromFavorites,
            searchPageType,
            pageData,
        } = this.props;
        const isFavorite = priorityCruise
            ? this.props.favorites.indexOf(priorityCruise.cruiseNid) > -1
            : false;
        const prioritySearchResultProps = {
            cruise: priorityCruise,
            isFavorite,
            portalId,
            addToFavorites,
            removeFromFavorites,
            discountThreshold: this.props.discountThreshold,
            searchPageType,
            deviceType,
        };
        const relevanceSortString: string = this.replaceRelevanceSortString();
        const reviewsLowPrice = getLowestPrice(results) || 0;
        const reviewsPriceCurrency = pageData.appConfig.defaultCurrency || currencyMap.EUR;

        return (
            <div
                style={style.wrapper}
                data-test-id="search-results"
                className="searchResultsWrapper"
            >
                {deviceType === 'desktop' && !!pages && !this.props.isLoading && (
                    <div className="searchResultControls onlyDesktop">
                        <Pagination
                            pages={pages}
                            currentPage={currentPage}
                            loadMore={this.props.loadMore}
                        />
                        <div className="selectBoxWrapper">
                            <select
                                className="selectBox"
                                onChange={(e) => this.handleSortOrderChange(e)}
                                value={this.props.searchParams.sortString}
                            >
                                <option value={relevanceSortString}>
                                    {__('Relevance', 'dreamlines')}
                                </option>
                                <option value="departure">
                                    {__('Departure (sort by)', 'dreamlines')}
                                </option>
                                <option value="price">
                                    {__('Price (cheapest first)', 'dreamlines')}
                                </option>
                                <option value="price_desc">
                                    {__('Price (highest first)', 'dreamlines')}
                                </option>
                                <option value="discount">
                                    {__('Discount (highest first)', 'dreamlines')}
                                </option>
                                <option value="duration_desc">
                                    {__('Duration (longest first)', 'dreamlines')}
                                </option>
                                <option value="duration_asc">
                                    {__('Duration (shortest first)', 'dreamlines')}
                                </option>
                            </select>
                        </div>
                    </div>
                )}
                {priorityCruise ? (
                    <TrackVisibility
                        partialVisibility={false}
                        throttleInterval={2000}
                        className="search-result-item"
                    >
                        <SearchResultItem
                            {...prioritySearchResultProps}
                            cabinKindFilterParams={getSortedCabinFilters(
                                this.props.searchParams.filterParams.cabinKind,
                            )}
                        />
                    </TrackVisibility>
                ) : null}
                {this.renderResults(
                    results.map((item) => ({
                        ...item,
                        deviceType,
                        sortString: this.props?.searchParams?.sortString,
                    })),
                )}
                {!!results?.length && (
                    <ReviewsGoogleScript
                        name={'SearchResultsPage'}
                        offerCount={results.length}
                        lowPrice={reviewsLowPrice}
                        priceCurrency={reviewsPriceCurrency}
                        reviewsType={'SearchResultsPage'}
                    />
                )}
                {deviceType === 'desktop' && !!pages && (
                    <div className="searchResultControls onlyDesktop" style={{ marginTop: '25px' }}>
                        <Pagination
                            pages={pages}
                            currentPage={currentPage}
                            loadMore={this.props.loadMore}
                        />
                    </div>
                )}
            </div>
        );
    }
}

// @TODO Fix state type inconsistency
const mapStateToProps = (state: IState) => ({
    portalId: state.pageData.appConfig.portalId,
    searchResults: state.search.searchResults,
    searchParams: state.search.searchParams,
    topSearchResults: state.search.topSearchResults,
    favorites: state.favoritePage.favorites,
    discountThreshold: state.pageData.discountThreshold,
    deviceType: state.pageData.deviceType,
    searchPageType: state.search.searchPageType,
    isLoading: state.search.isSearchResultsLoading,
    pageData: state.pageData,
});

const mapDispatchToProps = {
    loadMore,
    loadTopSearchResults,
    addToFavorites,
    removeFromFavorites,
};
// @TODO: Fix dispatch props

export const SearchResultList = connect<StateProps, any, any>(
    mapStateToProps,
    mapDispatchToProps,
)(SearchResultListClass);
