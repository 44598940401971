import * as React from 'react';
import styled from '@emotion/styled';
import { FilterFooter } from './SearchFilters/FilterFooter';
import { __ } from '../helpers/TranslationService';
import { useDispatch } from 'react-redux';
import { cancelSearch } from '../actions/searchActions';
import { Button } from './Button/Button';

const SearchModalOverlay = styled.div`
    height: calc(100% + 16px);
    width: calc(100% + 15px);
    background: ${({ theme }) => theme.colors.white};
    backdrop-filter: blur(12px);
    position: absolute;
    left: 0;
    top: -16px;
`;
const SearchModalContainer = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 1;
`;

const SearchModal = styled.div`
    width: 490px;
    height: 260px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.white[100]};
    position: absolute;
    top: 100px;
`;

const SearchModalSubTitle = styled.p`
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    margin-bottom: 24px;
`;

const SearchModalTitle = styled.h3`
    weight: 700;
    font-size: 32px;
    line-height: 50px;
    margin: 0;
`;

const ModalSubmit = styled(FilterFooter)`
    width: auto;
`;

type IProps = {
    numResults: number;
    toggleSearchFilter: () => void;
    doNewSearchViaUrl: () => void;
    isLoading: boolean;
};

export const SearchListModal = ({ numResults, isLoading, doNewSearchViaUrl }: IProps) => {
    const [modalTop, setModalTop] = React.useState(100);

    const dispatch = useDispatch();

    const handleScroll = React.useCallback(() => {
        const overlayHeight = document.querySelector('.search-overlay').scrollHeight - 100;
        const modalHeight = 258;
        const maxModalTop = overlayHeight - modalHeight;
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const newModalTop = Math.max(Math.min(scrollTop, maxModalTop), 100);

        setModalTop(newModalTop);
    }, [setModalTop]);

    React.useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const disableSearchButton = !numResults || numResults <= 0;
    const footerProps = {
        title: __('showOffers', 'dreamlines'),
        doNewSearchViaUrl: doNewSearchViaUrl,
        isLoading: isLoading,
        isDisabled: disableSearchButton,
        fullWidth: false,
        sticky: false,
    };

    const handleCancelSearch = () => {
        dispatch(cancelSearch());
    };

    return (
        <SearchModalContainer className="search-overlay">
            <SearchModalOverlay />
            <SearchModal style={{ top: modalTop }}>
                <SearchModalTitle>{`${numResults} ${__(
                    'cruisesFound',
                    'dreamlines',
                )}`}</SearchModalTitle>
                <SearchModalSubTitle>{__('basedOnSearch', 'dreamlines')}</SearchModalSubTitle>
                <ModalSubmit {...footerProps} testSelectorName="search-filter-request" />
                <Button color="flatPrimary" onClick={handleCancelSearch}>
                    {__('cancel', 'dreamlines')}
                </Button>
            </SearchModal>
        </SearchModalContainer>
    );
};
